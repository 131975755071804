<template>
  <!-- begin::Tracking documents -->
  <div class="white-box shadow-box p-6">
    <div
      class="manager-subtitle d-flex align-items-center justify-content-between"
    >
      <div>{{ $t("SHIPMENTS.DOCS.TITLE_2") }}</div>

      <div v-if="allowUpload">
        <input
          type="file"
          ref="inputUploadFile"
          style="display: none"
          @change="uploadDocument($event.target.files)"
        />
        <button
          class="btn button-filter btn-pill btn-tertiary"
          @click="$refs.inputUploadFile.click()"
          :disabled="Uploading"
        >
          <inline-svg src="/media/icons/upload-alt-2.svg" class="mr-3" />
          {{ $t("SHIPMENTS.DOCS.UPLOAD") }}
        </button>
      </div>
    </div>

    <div v-if="Loading" class="text-primary pl-3">
      {{ $t("GENERIC.SEARCHING") }}...
    </div>

    <div v-if="!Loading && DocumentList.length < 1" class="text-danger pl-3">
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>

    <div
      v-for="(item, index) in DocumentList"
      :key="index"
      class="row py-6 white-table border-box shadow-box mb-6"
    >
      <div class="col-lg-8 pt-2">
        <strong>{{ item.Name }}</strong>
      </div>
      <div class="col-lg-2 pt-2">
        {{ formatFilesize(item.Filesize) }}
      </div>
      <div class="col-lg-2 d-flex justify-content-end">
        <span class="action-icon" @click="downloadDocument(index)">
          <inline-svg src="/media/icons/download-primary.svg" />
        </span>
        <span
          v-if="item.AllowDelete"
          class="action-icon ml-6"
          @click="deleteDocument(index)"
        >
          <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
        </span>
      </div>
    </div>

    <div class="row mt-12 mb-6">
      <button
        class="btn btn-pill btn-white button-form button-form-primary ml-9"
        @click="$router.push(BackRoute)"
      >
        <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
        {{ $t("GENERIC.BACK") }}
      </button>
    </div>
  </div>
  <!-- end::Tracking documents -->
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

import ShipmentService from "@/core/services/api/v2/shipment.service";

export default {
  name: "TrackingDocuments",
  props: {
    ShipmentId: {
      type: Number,
      required: false,
      default: -1
    },
    BackRoute: {
      type: String,
      required: true,
      default: "home"
    }
  },

  data() {
    return {
      Loading: false,
      Uploading: false,
      Downloading: false,
      DocumentList: []
    };
  },

  computed: {
    ...mapGetters(["currentUserRoleID", "rolesList"]),

    allowUpload() {
      return (
        this.currentUserRoleID !== this.rolesList.ID_OBSERVER &&
        this.currentUserRoleID !== this.rolesList.ID_SUPERADMIN
      );
    }
  },

  mounted() {
    this.loadDocuments();
  },

  methods: {
    loadDocuments() {
      this.Loading = true;
      ShipmentService.getDocumentsAtTracking(this.ShipmentId).then(response => {
        if (response === 404) {
          this.$router.push("/manager/shipments");
        } else {
          this.DocumentList = response.Documents;
        }

        this.Loading = false;
      });
    },

    uploadDocument(newFile) {
      if (this.allowUpload && newFile) {
        // Disable upload button
        this.Uploading = true;

        ShipmentService.uploadDocumentAtTracking(
          this.ShipmentId,
          newFile[0]
        ).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 404) {
              whyError = this.$i18n.t("MY_PROFILE.ERROR_404");
            } else if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("GENERIC.SAVE_OK"),
              variant: "success",
              solid: true
            });

            this.loadDocuments();
          }

          // Reset submit button
          this.Uploading = false;
        });
      }
    },

    downloadDocument(index) {
      if (!this.Downloading) {
        this.Downloading = true;

        let whichDoc = this.DocumentList[index];
        if (whichDoc && whichDoc.Url) {
          const tempLink = document.createElement("a");
          tempLink.href = whichDoc.Url;
          tempLink.download = whichDoc.Name;
          tempLink.click();
          URL.revokeObjectURL(tempLink.href);
        }

        this.Downloading = false;
      }
    },

    deleteDocument(index) {
      let whichDoc = this.DocumentList[index];
      if (whichDoc && whichDoc.AllowDelete) {
        Swal.fire({
          title: this.$i18n.t("GENERIC.DELETE_ALERT") + "\n" + whichDoc.Name,
          text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
          cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
          customClass: {
            confirmButton: "btn btn-pill btn-danger button-form",
            cancelButton:
              "btn btn-pill btn-white button-form button-form-primary"
          },
          buttonsStyling: false
        }).then(result => {
          if (result.isConfirmed) {
            ShipmentService.deleteDocumentAtTracking(
              this.ShipmentId,
              whichDoc.ShipmentDocumentID
            ).then(statusCode => {
              if (statusCode >= 400) {
                this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                  title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                Swal.fire({
                  text: this.$i18n.t("GENERIC.DELETE_OK"),
                  icon: "success",
                  confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                  customClass: {
                    confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                  },
                  buttonsStyling: false,
                  focusConfirm: false
                });

                this.loadDocuments();
              }
            });
          }
        });
      }
    },

    formatFilesize(size) {
      let sizeKB = size / 1024;
      return sizeKB.toFixed(2) + " KB";
    }
  }
};
</script>
