<template>
  <!-- begin::Tracking chat -->
  <div class="white-box shadow-box p-6" ref="chat-wrapper">
    <div class="text-center mb-6" v-if="ShipmentInfo.OtherCompany">
      <h3>
        <strong>{{ ShipmentInfo.OtherAdmin }}</strong>
        ({{ ShipmentInfo.OtherCompany }})
      </h3>
      <h6>
        {{ ShipmentInfo.VehicleModelFirst }}
        <span v-if="ShipmentInfo.VehiclesCount > 1">
          {{
            $tc("CHATS.AND_VEHICLES", ShipmentInfo.VehiclesCount - 1, {
              count: ShipmentInfo.VehiclesCount - 1
            })
          }}
        </span>
      </h6>
    </div>
    <perfect-scrollbar class="chat-scroll" style="max-height: 35vh">
      <div class="messages"></div>
    </perfect-scrollbar>

    <div
      v-if="AllowSend"
      class="mt-6 border-top-200 pt-6 d-flex align-items-center justify-content-between"
    >
      <textarea
        v-model="NewMessage"
        rows="1"
        class="chat-input form-control"
        style="resize: none"
        @keydown="onChatInputKeydown"
      >
      </textarea>
      <button
        class="chat-send btn btn-pill btn-tertiary button-form px-6 ml-3"
        style="min-width: 150px"
        @click="sendMessage"
      >
        <inline-svg src="/media/icons/send.svg" class="mr-3" />
        {{ $t("GENERIC.SEND") }}
      </button>
    </div>

    <div class="row button-row mt-12">
      <button
        class="btn btn-pill btn-white button-form button-form-primary mr-9"
        @click="$router.push(BackRoute)"
      >
        <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
        {{ $t("GENERIC.BACK") }}
      </button>
    </div>
  </div>
  <!-- end::Tracking chat -->
</template>

<style>
.date-separator {
  margin: 0;
  padding: 0;
  text-align: center;
  line-height: 2;
}
.date-separator:before,
.date-separator:after {
  display: inline-block;
  content: " ";
  text-shadow: none;
  width: 40%;
  border-top: 1px solid #e6e7e9;
  vertical-align: top;
  margin-top: 1em;
}
.date-separator:before {
  margin-right: 1em;
}
.date-separator:after {
  margin-left: 1em;
}
</style>

<script>
import KTUtil from "@/assets/js/components/util";
import KTLayoutContent from "@/assets/js/layout/base/content";

import { mapGetters } from "vuex";
import moment from "moment";

import i18nService from "@/core/services/i18n.service.js";
import ShipmentService from "@/core/services/api/v2/shipment.service";

export default {
  name: "TrackingChat",
  props: {
    ShipmentId: {
      type: Number,
      required: false,
      default: -1
    },
    BackRoute: {
      type: String,
      required: true,
      default: "home"
    }
  },

  data() {
    return {
      Loading: false,
      ShipmentInfo: {},
      AllowSend: false,
      MessageList: [],
      NewMessage: "",
      DateFormatEN: "YYYY - MM - DD",
      DateFormatES: "DD / MM / YYYY",
      DateFormatLang: "DD / MM / YYYY",
      DefaultAvatar: "/media/users/blank.png",
      UpdateIntervalMilis: 15000,
      UpdateIntervalID: 0
    };
  },

  computed: {
    ...mapGetters(["currentUserRoleID", "rolesList"])
  },

  mounted() {
    // Format for dates
    let userLang = i18nService.getActiveLanguage();
    moment.locale(userLang);
    if (userLang === "en") {
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFormatLang = this.DateFormatES;
    }

    // Get initial messages
    this.initScrollbar();
    this.loadInitialMessages();

    // Get new messages every X time
    this.UpdateIntervalID = setInterval(
      this.loadNewMessages,
      this.UpdateIntervalMilis
    );
  },

  beforeDestroy() {
    clearInterval(this.UpdateIntervalID);
  },

  methods: {
    initScrollbar() {
      // Initialize perfect scrollbar(see:  https://github.com/utatti/perfect-scrollbar)
      let chatWrapper = this.$refs["chat-wrapper"];
      var scrollZone = KTUtil.find(chatWrapper, ".chat-scroll");
      if (!scrollZone) {
        return;
      }

      KTUtil.scrollInit(scrollZone, {
        windowScroll: false, // allow browser scroll when the scroll reaches the end of the side
        mobileNativeScroll: true, // enable native scroll for mobile
        desktopNativeScroll: false, // disable native scroll and use custom scroll for desktop
        resetHeightOnDestroy: true, // reset css height on scroll feature destroyed
        handleWindowResize: true, // recalculate hight on window resize
        rememberPosition: true, // remember scroll position in cookie
        height: function() {
          // Calculate height
          var height;

          if (KTUtil.isBreakpointDown("lg")) {
            // Mobile mode
            return KTUtil.hasAttr(scrollZone, "data-mobile-height")
              ? parseInt(KTUtil.attr(scrollZone, "data-mobile-height"))
              : 400;
          } else if (
            KTUtil.isBreakpointUp("lg") &&
            KTUtil.hasAttr(scrollZone, "data-height")
          ) {
            // Desktop Mode
            return parseInt(KTUtil.attr(scrollZone, "data-height"));
          } else {
            height =
              KTLayoutContent.getHeight() -
              parseInt(KTUtil.css(scrollZone, "margin-top")) -
              parseInt(KTUtil.css(scrollZone, "margin-bottom"));
          }

          // Remove additional space
          height = height - 2;

          return height;
        }
      });
    },

    loadInitialMessages() {
      this.Loading = true;
      ShipmentService.getChatAtTracking(this.ShipmentId).then(response => {
        if (response === 404) {
          this.$router.push("/manager/shipments");
        } else {
          // Only allow new messages if really after phase AND not Observer
          this.ShipmentInfo = response.ShipmentInfo;
          this.AllowSend =
            parseInt(this.ShipmentInfo.ShipmentStatusID) > 5 &&
            this.currentUserRoleID !== this.rolesList.ID_OBSERVER;

          // Draw previous messages
          this.MessageList = response.Messages.reverse();
          let messagesCount = response.Messages.length;

          if (messagesCount > 0) {
            for (let m = 0; m < messagesCount; m++) {
              this.drawMessage(m);
            }
          }
        }

        this.Loading = false;
      });
    },

    loadNewMessages() {
      if (!this.Loading) {
        this.Loading = true;
        let previousMsgCount = this.MessageList.length;
        let lastMsgID =
          previousMsgCount > 0
            ? this.MessageList[previousMsgCount - 1].ShipmentChatID
            : 0;

        ShipmentService.getChatNewAtTracking(this.ShipmentId, lastMsgID).then(
          response => {
            let newMessagesCount = response.length;
            if (newMessagesCount > 0) {
              for (let m = newMessagesCount - 1; m >= 0; m--) {
                this.MessageList.push(response[m]);
                this.drawMessage(this.MessageList.length - 1);
              }
            }

            this.$emit("chat-new-messages", newMessagesCount);
            this.Loading = false;
          }
        );
      }
    },

    onChatInputKeydown(e) {
      // Send on "Enter"
      if (e.keyCode === 13) {
        this.sendMessage();
        e.preventDefault();
        return false;
      }
    },

    sendMessage() {
      if (this.AllowSend) {
        let text = this.NewMessage.trim();
        if (text.length > 0) {
          this.Loading = true;
          this.NewMessage = "";

          // Do send
          ShipmentService.sendChatAtTracking(this.ShipmentId, text).then(
            response => {
              if (!response.Message) {
                this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                  title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                this.MessageList.push(response.Message);
                this.drawMessage(this.MessageList.length - 1);
              }

              this.Loading = false;
            }
          );
        }
      }
    },

    drawMessage(m) {
      let chatWrapper = this.$refs["chat-wrapper"];
      let messagesZone = KTUtil.find(chatWrapper, ".messages");

      // Date separator
      let msg = this.MessageList[m];
      let msgDate = moment(msg.CreatedAt).format(this.DateFormatLang);

      let previousMsg = m > 0 ? this.MessageList[m - 1] : null;
      let previousDate = previousMsg
        ? moment(previousMsg.CreatedAt).format(this.DateFormatLang)
        : "xxx";

      if (msgDate !== previousDate) {
        var dateNode = document.createElement("h5");
        KTUtil.addClass(
          dateNode,
          "date-separator font-weight-bolder text-dark-50 my-9"
        );
        KTUtil.setHTML(dateNode, msgDate);
        messagesZone.appendChild(dateNode);
      }

      // Message details
      let fromMyself = false;
      let fromName = "";
      let fromAvatar = "";
      if (msg.From) {
        fromName = msg.From.Name;
        fromAvatar =
          msg.From.Avatar && msg.From.Avatar.length > 0
            ? msg.From.Avatar
            : this.DefaultAvatar;
      } else {
        fromMyself = true;
        fromName = this.$i18n.t("CHATS.FROM_MYSELF");
        fromAvatar = this.currentUserAvatar
          ? this.currentUserAvatar
          : this.defaultAvatar;
      }

      var html = '<div class="d-flex align-items-top">';

      if (!fromMyself) {
        html += '<div class="symbol symbol-circle symbol-40 mr-3">';
        html += '<img alt="Pic" src="' + fromAvatar + '"/>';
        html += "</div>";
      }

      html += '<div class="bg-primary-o-25 py-3 px-6">';
      html += '<span class="font-weight-boldest">' + fromName + "</span>";
      html +=
        '<span class="font-weight-bolder text-dark-50 ml-6">' +
        moment(msg.CreatedAt).format("HH:mm") +
        "</span>";

      html += "<br />" + msg.Content;
      html += "</div>";

      if (fromMyself) {
        html += '<div class="symbol symbol-circle symbol-40 ml-3">';
        html += '<img alt="Pic" src="' + fromAvatar + '"/>';
        html += "</div>";
      }

      html += "</div>";

      var msgNode = document.createElement("div");
      KTUtil.addClass(msgNode, "d-flex flex-column mb-5");
      if (fromMyself) {
        KTUtil.addClass(msgNode, "align-items-end");
      } else {
        KTUtil.addClass(msgNode, "align-items-start");
      }
      KTUtil.setHTML(msgNode, html);
      messagesZone.appendChild(msgNode);

      // Scroll to last
      let scrollZone = KTUtil.find(chatWrapper, ".chat-scroll");
      scrollZone.scrollTop = parseInt(KTUtil.css(messagesZone, "height"));
      let ps = KTUtil.data(scrollZone).get("ps");
      if (ps) {
        ps.update();
      }
    }
  }
};
</script>
