<template>
  <!-- begin::Tracking profile tabs -->
  <div v-if="isAuthenticated && !Loading">
    <div class="manager-title mb-1">
      <div>
        {{ $t("SHIPMENTS.TITLE_EDIT") + " - ID: " + ShipmentID }}
        <hr class="manager-title-sub" />
      </div>
    </div>

    <v-tabs v-model="ActiveTab" align-with-title class="mb-3 mx-0">
      <v-tabs-slider color="#61bb0c"></v-tabs-slider>

      <v-tab href="#planning">
        <span v-if="AlertCountOther === 0">
          {{ this.$i18n.t("SHIPMENTS.PLANNING.TITLE") }}
        </span>
        <v-badge v-else inline color="bg-danger text-white">
          <template v-slot:badge>
            {{ AlertCountOther }}
          </template>
          <span class="mr-3">
            {{ this.$i18n.t("SHIPMENTS.PLANNING.TITLE") }}
          </span>
        </v-badge>
      </v-tab>

      <v-tab href="#docs">
        <span v-if="AlertCountDocument === 0">
          {{ this.$i18n.t("SHIPMENTS.DOCS.TITLE") }}
        </span>
        <v-badge v-else inline color="bg-danger text-white">
          <template v-slot:badge>
            {{ AlertCountDocument }}
          </template>
          <span class="mr-3">{{ this.$i18n.t("SHIPMENTS.DOCS.TITLE") }}</span>
        </v-badge>
      </v-tab>

      <v-tab href="#incidents">
        <span v-if="AlertCountIncident === 0">
          {{ this.$i18n.t("SHIPMENTS.INCIDENTS.TITLE") }}
        </span>
        <v-badge v-else inline color="bg-danger text-white">
          <template v-slot:badge>
            {{ AlertCountIncident }}
          </template>
          <span class="mr-3">
            {{ this.$i18n.t("SHIPMENTS.INCIDENTS.TITLE") }}
          </span>
        </v-badge>
      </v-tab>

      <v-tab href="#messages">
        <span v-if="AlertCountChat === 0">
          {{ this.$i18n.t("OFFERS.COMMON.MESSAGES") }}
        </span>
        <v-badge v-else inline color="bg-danger text-white">
          <template v-slot:badge>
            {{ AlertCountChat }}
          </template>
          <span class="mr-3">{{ this.$i18n.t("OFFERS.COMMON.MESSAGES") }}</span>
        </v-badge>
      </v-tab>
    </v-tabs>

    <v-tabs-items class="mx-0" :value="ActiveTab">
      <v-tab-item value="planning">
        <TrackingEdit
          :shipment-id="ShipmentID"
          :shipment-leg-vehicle-id="ShipmentLegVehicleID"
          :back-route="BackRoute"
        />
      </v-tab-item>

      <v-tab-item value="docs">
        <TrackingDocuments :shipment-id="ShipmentID" :back-route="BackRoute" />
      </v-tab-item>

      <v-tab-item value="incidents">
        <TrackingIncidents
          :shipment-id="ShipmentID"
          :shipment-leg-vehicle-id="ShipmentLegVehicleID"
          :back-route="BackRoute"
        />
      </v-tab-item>

      <v-tab-item value="messages">
        <TrackingChat
          :shipment-id="ShipmentID"
          :back-route="BackRoute"
          @chat-new-messages="AlertCountChat = $event"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!-- end::Tracking profile tabs -->
</template>

<script>
import { mapGetters } from "vuex";

import ShipmentService from "@/core/services/api/v2/shipment.service";
import TrackingChat from "@/view/pages/manager/shipments/tracking/Chat.vue";
import TrackingDocuments from "@/view/pages/manager/shipments/tracking/Documents.vue";
import TrackingEdit from "@/view/pages/manager/shipments/tracking/Edit.vue";
import TrackingIncidents from "@/view/pages/manager/shipments/tracking/incidents/Index.vue";

export default {
  name: "TrackingTabs",
  components: {
    TrackingChat,
    TrackingDocuments,
    TrackingEdit,
    TrackingIncidents
  },

  computed: {
    ...mapGetters(["isAuthenticated"]),
    ActiveTab: {
      set(ActiveTab) {
        this.$router.replace({ query: { ...this.$route.query, ActiveTab } });
      },
      get() {
        return this.$route.query.ActiveTab;
      }
    },
    BackRoute() {
      let fromPage = this.$route.query.FromPage;
      if (fromPage === "chats") {
        return "/manager/chats?ActiveTab=shipments";
      } else if (fromPage === "incidents") {
        return "/manager/incidents";
      } else {
        return "/manager/shipments";
      }
    }
  },

  data() {
    return {
      Loading: true,
      ShipmentLegVehicleID: 0,
      ShipmentID: 0,
      AlertCountOther: 0,
      AlertCountDocument: 0,
      AlertCountIncident: 0,
      AlertCountChat: 0
    };
  },

  mounted() {
    if (!this.$route.params.id) {
      this.$router.push("/manager/shipments");
    } else {
      this.ShipmentLegVehicleID = parseInt(this.$route.params.id);
      this.loadAlertCount();
    }
  },

  methods: {
    loadAlertCount() {
      this.AlertCountChat = 0;
      this.AlertCountOther = 0;

      ShipmentService.getAlertCountAtTracking(this.ShipmentLegVehicleID).then(
        response => {
          this.AlertCountChat = response.AlertCountChat;
          this.AlertCountOther = response.AlertCountOther;
          this.AlertCountDocument = response.AlertCountDocument;
          this.AlertCountIncident = response.AlertCountIncident;
          this.ShipmentID = response.ShipmentID;
          this.Loading = false;
        }
      );
    }
  }
};
</script>
