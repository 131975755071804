<template>
  <!-- begin::Tracking edit -->
  <div v-if="InitialData">
    <div class="white-box shadow-box">
      <v-form ref="formBasic" v-model="Valid" lazy-validation class="form">
        <!-- begin::Status of this item -->
        <div class="mt-6">
          <div>
            <span class="manager-subtitle mr-9">
              {{ $t("SHIPMENTS.STATUS") }}
            </span>
            <span
              class="white-box shadow-box border-2 p-3 text-uppercase"
              :class="formatStatusClass()"
              style="white-space: nowrap"
            >
              {{ formatStatusName() }}
            </span>
          </div>
        </div>

        <div class="mt-6">
          <inline-svg class="mx-3" src="/media/icons/help-alt-5-success.svg" />
          <span v-if="isTransporter">
            {{
              $t(
                "SHIPMENTS.PLANNING.STATUS_INFO." +
                  InitialData.ShipmentStatusID +
                  ".TRANSPORTER"
              )
            }}
          </span>
          <span v-else>
            {{
              $t(
                "SHIPMENTS.PLANNING.STATUS_INFO." +
                  InitialData.ShipmentStatusID +
                  ".SENDER"
              )
            }}
          </span>
        </div>
        <!-- end::Status of this item -->

        <!-- begin::Parent Shipment/Offer details -->
        <div class="manager-subtitle mt-9">
          {{ $t("OFFERS.STANDARD.ABSTRACT") }}
        </div>

        <div class="row">
          <!-- begin::Other company logo -->
          <div class="col-lg-2">
            <v-img
              :src="
                isTransporter
                  ? InitialData.Sender.Logo
                  : InitialData.Transporter.Logo
              "
              class="mx-6"
            />
            <button
              v-if="!isObserver"
              class="btn btn-pill btn-white button-filter miniButton"
              style="min-width: 0"
              @click.prevent="DetailsOpen = !DetailsOpen"
            >
              <inline-svg
                :src="
                  '/media/icons/chevron-' +
                    (DetailsOpen ? 'up' : 'down') +
                    '-primary.svg'
                "
                class="mr-3"
              />
              {{ $t("GENERIC.DETAILS") }}
            </button>
          </div>
          <!-- end::Other company logo -->

          <!-- begin::Other company & total price -->
          <div class="col-lg-3">
            <strong>
              {{
                $t(
                  isTransporter
                    ? "OFFERS.COMMON.SENDER"
                    : "OFFERS.COMMON.TRANSPORTER"
                )
              }}
            </strong>
            <p>
              {{
                isTransporter
                  ? InitialData.Sender.TradeName
                  : InitialData.Transporter.TradeName
              }}
            </p>
            <strong v-if="!isObserver">
              {{ $t("OFFERS.COMMON.FARE") }}
              {{ $t("FARE_TYPE_NAMES_BY_ID." + InitialData.FareTypeID) }}
              <br />
              {{ $t("OFFERS.COMMON.PRICE_TOTAL") }}:
              {{ formatTotalPrice() }}
            </strong>
          </div>
          <!-- end::Other company & total price -->

          <!-- begin::Legs -->
          <div class="col-lg-7">
            <p>
              <strong>{{ $t("SHIPMENTS.ORIGIN") }}:</strong>
              {{ formatExtremeAddress(InitialData.OriginAddress) }}
              <inline-svg
                src="/media/icons/trayecto-primary.svg"
                class="mx-6"
              />
              <strong>{{ $t("SHIPMENTS.DESTINATION") }}:</strong>
              {{ formatExtremeAddress(InitialData.DestinationAddress) }}
            </p>

            <p>
              <strong>
                {{
                  InitialData.Back && InitialData.Back.VehicleCount > 0
                    ? $t("OFFERS.COMMON.THERE")
                    : $t("OFFERS.COMMON.JUST_THERE")
                }}:
              </strong>
              {{
                $tc(
                  "SHIPMENTS.PLANNING.VEHICLE_COUNT",
                  InitialData.There.VehicleCount
                ) +
                  ", " +
                  $t("OFFERS.COMMON.DESTINATION_DATE") +
                  " " +
                  formatDestinationDates("There", "Desired")
              }}
            </p>

            <p v-if="InitialData.Back && InitialData.Back.VehicleCount > 0">
              <strong>{{ $t("OFFERS.COMMON.BACK") }}:</strong>
              {{
                $tc(
                  "SHIPMENTS.PLANNING.VEHICLE_COUNT",
                  InitialData.Back.VehicleCount
                ) +
                  ", " +
                  $t("OFFERS.COMMON.DESTINATION_DATE") +
                  " " +
                  formatDestinationDates("Back", "Desired")
              }}
            </p>
          </div>
          <!-- end::Legs -->

          <!-- begin::Details -->
          <div
            v-if="!isObserver && DetailsOpen"
            class="col-lg-12 pt-3 row mb-6"
          >
            <!-- begin:Price -->
            <div class="col-lg-4">
              <div
                class="w-100 d-flex align-items-baseline justify-content-between"
              >
                <span class="text-primary priceLine">
                  <strong>
                    {{ $t("SHIPMENTS.TOTAL").toUpperCase() }}
                  </strong>
                </span>
                <span class="text-primary priceTotal">
                  {{ formatTotalPrice() }}
                </span>
              </div>
              <div class="text-right mb-3">
                {{ $t("SHIPMENTS.TAXES_INCLUDED") }}
              </div>

              <strong class="mb-0">
                {{ $t("SHIPMENTS.PAYMENT_DATA.TITLE") }}:
              </strong>
              <div v-if="InitialData.PaymentData.CardType === 'External'">
                <strong class="text-danger">
                  {{ $t("SHIPMENTS.PAYMENT_DATA.EXTERNAL") }}
                </strong>
              </div>
              <div v-else>
                <strong>{{ $t("SHIPMENTS.PAYMENT_DATA.CREDIT_CARD") }}:</strong>
                {{ InitialData.PaymentData.CardType }}
                <br />
                {{
                  $t("SHIPMENTS.PAYMENT_DATA.CARD_NUMBER") +
                    ": **** **** **** " +
                    InitialData.PaymentData.CardLast4
                }}
                <br />
                {{
                  $t("SHIPMENTS.PAYMENT_DATA.AMOUNT") +
                    ": " +
                    InitialData.PaymentData.Amount.toFixed(2).replace(
                      ".",
                      ","
                    ) +
                    " " +
                    InitialData.PaymentData.Currency
                }}
              </div>
            </div>
            <!-- end:Price -->

            <!-- begin:Transporter data -->
            <div v-if="isSender || isObserver" class="col-lg-4">
              <strong>{{ $t("OFFERS.COMMON.TRANSPORTER") }}:</strong>
              <p>
                <strong>{{ InitialData.Transporter.FiscalName }}</strong>
                <br />
                <strong>
                  {{
                    $t("SHIPMENTS.PLANNING.INVOICE_TO_ID") +
                      ": " +
                      InitialData.Transporter.FiscalID
                  }}
                </strong>
                <br />
                {{ InitialData.Transporter.Street }}
                <br />
                {{
                  InitialData.Transporter.PostalCode +
                    " - " +
                    InitialData.Transporter.City +
                    " (" +
                    ProvinceNames[InitialData.Transporter.ProvinceID] +
                    ")"
                }}
                <br />
                {{ InitialData.Transporter.ContactEmail }}
                <br />
                {{ InitialData.Transporter.ContactPhone }}
              </p>
            </div>
            <!-- end:Transporter data -->

            <!-- begin:Sender data -->
            <div v-if="isTransporter" class="col-lg-4">
              <strong>{{ $t("OFFERS.COMMON.SENDER") }}:</strong>
              <p>
                <strong>{{ InitialData.Sender.TradeName }}</strong>
                <br />
                {{ InitialData.Sender.Street }}
                <br />
                {{
                  InitialData.Sender.PostalCode +
                    " - " +
                    InitialData.Sender.City +
                    " (" +
                    ProvinceNames[InitialData.Sender.ProvinceID] +
                    ")"
                }}
                <br />
                {{ InitialData.Sender.ContactEmail }}
                <br />
                {{ InitialData.Sender.ContactPhone }}
              </p>
            </div>
            <!-- end:Sender data -->

            <!-- begin:Invoice data -->
            <div v-if="!isObserver" class="col-lg-4">
              <strong>{{ $t("SHIPMENTS.PLANNING.INVOICE_TO_TITLE") }}:</strong>
              <p v-if="InitialData.InvoiceCenter.InvoiceCenterID > 0">
                <strong>{{ InitialData.InvoiceCenter.FiscalName }}</strong>
                <br />
                <strong>
                  {{
                    $t("SHIPMENTS.PLANNING.INVOICE_TO_ID") +
                      ": " +
                      InitialData.InvoiceCenter.FiscalID
                  }}
                </strong>
                <br />
                {{ InitialData.InvoiceCenter.Street }}
                <br />
                {{
                  InitialData.InvoiceCenter.PostalCode +
                    " - " +
                    InitialData.InvoiceCenter.City +
                    " (" +
                    ProvinceNames[InitialData.InvoiceCenter.ProvinceID] +
                    ")"
                }}
                <br />
                {{ InitialData.InvoiceCenter.EmailAccounting }}
              </p>
              <p v-else>
                <strong>{{ InitialData.Sender.FiscalName }}</strong>
                <br />
                <strong>
                  {{
                    $t("SHIPMENTS.PLANNING.INVOICE_TO_ID") +
                      ": " +
                      InitialData.Sender.FiscalID
                  }}
                </strong>
                <br />
                {{ InitialData.Sender.Street }}
                <br />
                {{
                  InitialData.Sender.PostalCode +
                    " - " +
                    InitialData.Sender.City +
                    " (" +
                    ProvinceNames[InitialData.Sender.ProvinceID] +
                    ")"
                }}
              </p>
            </div>
            <!-- end:Invoice data -->
          </div>
          <!-- end::Details -->
        </div>
        <!-- end::Other company & price -->

        <!-- begin::Other data, just for Senders -->
        <div v-if="isSender">
          <div class="manager-subtitle mt-9">
            {{ $t("OFFERS.COMMON.OTHER_DATA") }}
          </div>

          <div class="row">
            <!-- begin::Observers -->
            <div
              class="form-group"
              :class="
                InvoiceCenterOptions.length > 0 ? 'col-lg-6' : 'col-lg-12'
              "
            >
              <div class="row">
                <div :class="canEditSenderSide ? 'col-lg-11' : 'col-lg-12'">
                  <label>{{ $t("OFFERS.COMMON.OBSERVERS") }}</label>
                  <v-text-field
                    v-if="!canEditSenderSide"
                    class="form-control"
                    :value="getCurrentObserversNick()"
                    readonly
                  />
                  <v-select
                    v-else
                    id="ObserversSelect"
                    multiple
                    v-model="Fields.Observers"
                    :items="ObserverOptions"
                    chips
                    small-chips
                    deletable-chips
                    class="form-control px-2"
                    style="padding-top: 0.35em !important; height: fit-content !important"
                  ></v-select>
                </div>
                <div v-if="canEditSenderSide" class="col-lg-1 pt-11 pl-0">
                  <span class="action-icon" @click="openObserverDialog">
                    <inline-svg src="/media/icons/add-primary.svg" />
                  </span>

                  <!--begin::Observer dialog-->
                  <v-dialog
                    v-model="ObserverDialog.Show"
                    transition="dialog-bottom-transition"
                    max-width="700px"
                  >
                    <v-card>
                      <div class="bg-primary text-white font-size-h5 py-3 px-7">
                        {{ $t("OBSERVERS.TITLE_ADD") }}
                      </div>

                      <v-card-text class="pt-6 pb-3">
                        <v-form
                          ref="formObserverDialog"
                          v-model="ObserverDialog.Valid"
                          lazy-validation
                          class="form"
                        >
                          <div class="row">
                            <div class="form-group col-lg-6">
                              <label>
                                {{ $t("MY_PROFILE.NICK") }}
                              </label>
                              <v-text-field
                                class="form-control"
                                v-model="ObserverDialog.Nick"
                                :placeholder="$t('MY_PROFILE.NICK')"
                                required
                                :rules="[rules.required]"
                              />
                            </div>

                            <div class="form-group col-lg-6">
                              <label>
                                {{ $t("MY_PROFILE.FULLNAME") }}
                              </label>
                              <v-text-field
                                class="form-control"
                                v-model="ObserverDialog.Name"
                                :placeholder="$t('MY_PROFILE.FULLNAME')"
                                required
                                :rules="[rules.required]"
                              />
                            </div>

                            <div class="form-group col-lg-6">
                              <label>
                                {{ $t("OBSERVERS.NICK_COMPANY") }}
                              </label>
                              <v-text-field
                                class="form-control"
                                v-model="ObserverDialog.NickCompany"
                                :placeholder="$t('OBSERVERS.NICK_COMPANY')"
                                required
                                :rules="[rules.required]"
                              />
                            </div>
                          </div>

                          <div class="row">
                            <div class="form-group col-lg-6">
                              <label>
                                {{ $t("MY_PROFILE.EMAIL") }}
                              </label>
                              <v-text-field
                                class="form-control"
                                v-model="ObserverDialog.Email"
                                :placeholder="$t('MY_PROFILE.EMAIL')"
                                :rules="[rules.required, rules.email]"
                                required
                              >
                                <template v-slot:prepend-inner>
                                  <inline-svg
                                    src="/media/icons/contact-gray.svg"
                                  />
                                </template>
                              </v-text-field>
                            </div>

                            <div class="col-lg-6 d-flex align-items-center">
                              <inline-svg
                                src="/media/icons/help-alt-5-primary.svg"
                              />
                              <span class="pl-3">
                                {{ $t("USERS.NEW_EMAIL_INFO") }}
                              </span>
                            </div>
                          </div>

                          <div class="row button-row py-3">
                            <button
                              class="btn btn-pill btn-white button-form button-form-primary mr-9"
                              @click="ObserverDialog.Show = false"
                            >
                              {{ $t("GENERIC.CANCEL") }}
                            </button>
                            <button
                              class="btn btn-pill btn-tertiary button-form"
                              :disabled="!ObserverDialog.Valid"
                              @click="saveObserverDialog"
                            >
                              {{ $t("GENERIC.OK") }}
                            </button>
                          </div>
                        </v-form>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <!--end::Observer dialog-->
                </div>
              </div>
            </div>
            <!-- end::Observers -->

            <!-- begin::InvoiceCenter -->
            <div
              v-if="InvoiceCenterOptions.length > 0"
              class="col-lg-6 form-group"
            >
              <label>{{ $t("OFFERS.COMMON.INVOICE_CENTER") }}</label>
              <v-text-field
                v-if="!canEditSenderSide"
                class="form-control"
                :value="getCurrentInvoiceCenterNick()"
                readonly
              />
              <select
                v-else
                class="form-control select-down"
                v-model="Fields.InvoiceCenterID"
              >
                <option
                  v-for="ic in InvoiceCenterOptions"
                  :key="ic.InvoiceCenterID"
                  :value="ic.InvoiceCenterID"
                  class="text-primary"
                >
                  {{ ic.Nick }}
                </option>
              </select>
            </div>
            <!-- end::InvoiceCenter -->
          </div>
        </div>
        <!-- end::Other data -->

        <!-- begin::Origin & Destination -->
        <div class="row mt-6">
          <div
            v-for="whichSide in ['OriginAddress', 'DestinationAddress']"
            :key="whichSide"
            class="col-lg-6"
          >
            <h4 class="font-weight-bolder">
              <inline-svg src="/media/icons/pin-1-primary.svg" class="mr-3" />
              {{
                $t(
                  whichSide === "OriginAddress"
                    ? "OFFERS.STANDARD.ORIGIN_DATA"
                    : "OFFERS.STANDARD.DESTINATION_DATA"
                )
              }}
            </h4>
            <p class="mt-6 font-weight-bolder">
              {{
                $t(
                  whichSide === "OriginAddress"
                    ? "OFFERS.STANDARD.ORIGIN_ADDRESS"
                    : "OFFERS.STANDARD.DESTINATION_ADDRESS"
                )
              }}
            </p>
            <div class="row">
              <div class="col-lg-12 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].TradeName"
                  readonly
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-8 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].Street"
                  readonly
                />
              </div>
              <div class="col-lg-4 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].PostalCode"
                  readonly
                />
              </div>
              <div class="col-lg-6 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].City"
                  readonly
                />
              </div>
              <div class="col-lg-6 form-group">
                <v-text-field
                  class="form-control"
                  :value="ProvinceNames[InitialData[whichSide].ProvinceID]"
                  readonly
                />
              </div>
            </div>
            <p class="mt-3 font-weight-bolder">
              {{
                $t(
                  whichSide === "OriginAddress"
                    ? "OFFERS.STANDARD.ORIGIN_CONTACT"
                    : "OFFERS.STANDARD.DESTINATION_CONTACT"
                )
              }}
            </p>
            <div class="row">
              <div class="col-lg-12 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].ContactName"
                  readonly
                />
              </div>
              <div class="col-lg-6 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].ContactEmail"
                  readonly
                >
                  <template v-slot:prepend-inner>
                    <inline-svg src="/media/icons/contact-gray.svg" />
                  </template>
                </v-text-field>
              </div>
              <div class="col-lg-6 form-group">
                <v-text-field
                  class="form-control"
                  :value="InitialData[whichSide].ContactPhone"
                  readonly
                >
                  <template v-slot:prepend-inner>
                    <inline-svg src="/media/icons/phone-gray.svg" />
                  </template>
                </v-text-field>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Origin & Destination -->

        <!--begin::Vehicle-->
        <div class="row mt-3">
          <div class="form-group col-lg-12">
            <h5 class="font-weight-bolder">
              <inline-svg src="/media/icons/car-primary.svg" class="mr-3" />
              {{ $t("SHIPMENTS.PLANNING.VEHICLE_DATA") }}
            </h5>
            <strong>{{ InitialData.Vehicle.Plate }}</strong>
            -
            {{
              InitialData.Vehicle.Brand +
                ", " +
                InitialData.Vehicle.Model +
                ", " +
                InitialData.Vehicle.Version +
                ", " +
                InitialData.Vehicle.Year +
                ". " +
                InitialData.Vehicle.VehicleTypeName +
                ", " +
                InitialData.Vehicle.VehicleStatusName
            }}.
            <div
              v-for="whichSideServices in ['Origin', 'Destination']"
              :key="whichSideServices"
            >
              <span class="pl-9">
                {{
                  $t(
                    "PRICE_SEARCH.STEP3.EXTRA_DATA.SERVICES_" +
                      whichSideServices.toUpperCase()
                  )
                }}:
              </span>
              <span
                v-if="
                  !InitialData.Vehicle['Services' + whichSideServices] ||
                    InitialData.Vehicle['Services' + whichSideServices]
                      .length === 0
                "
              >
                {{ $t("GENERIC.N_D") }}.
              </span>
              <span v-else>
                {{
                  InitialData.Vehicle["Services" + whichSideServices].join(
                    ", "
                  )
                }}.
              </span>
            </div>
          </div>
        </div>
        <!--end::Vehicle-->

        <!--begin::Driver, just for Transporters-->
        <div v-if="isTransporter">
          <h5 class="font-weight-bolder">
            <inline-svg src="/media/icons/choferes-primary.svg" class="mr-3" />
            {{ $t("SHIPMENTS.DRIVER") }}
          </h5>
          <div class="row">
            <div class="col-lg-6 form-group pt-3">
              <v-text-field
                v-if="!canEditDriverAndDates"
                class="form-control"
                :value="getCurrentDriverNick()"
                readonly
              />
              <select
                v-else
                class="form-control select-down"
                v-model="Fields.DriverUserID"
              >
                <option
                  v-for="d in DriverOptions"
                  :key="d.UserID"
                  :value="d.UserID"
                  class="text-primary"
                >
                  {{ d.Nick }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!--end::Driver-->

        <!--begin::Dates-->
        <div class="row">
          <!--begin::OriginDate-->
          <div class="col-lg-6 form-group">
            <h5 class="font-weight-bolder">
              <inline-svg
                src="/media/icons/calendar-alt-1-primary.svg"
                class="mr-3"
              />
              {{ $t("OFFERS.COMMON.ORIGIN_DATE") }}
            </h5>

            <!--begin::OriginDateDesired-->
            <p>
              <strong>{{ $t("OFFERS.COMMON.AVAILABILITY") }}:</strong>
              {{ formatOneDate("Origin", "Desired") }}
            </p>
            <!--end::OriginDateDesired-->

            <!--begin::OriginDatePlanned-->
            <p class="mt-10 mb-3">
              <strong>{{ $t("OFFERS.COMMON.PLANNED") }}</strong>
            </p>
            <v-text-field
              class="form-control"
              :class="canEditDriverAndDates ? 'bg-white' : ''"
              :value="formatOriginDatePlanned()"
              readonly
              @click="openOriginDatePlannedDialog"
            />

            <!--begin::OriginDatePlanned dialog-->
            <v-dialog
              v-model="OriginDatePlannedDialog.Show"
              :return-value="OriginDatePlannedDialog.Value"
              persistent
              width="320px"
            >
              <v-date-picker
                v-model="OriginDatePlannedDialog.Value"
                color="tertiary"
                scrollable
                range
                :locale="DateLocale"
                :first-day-of-week="DateFirstDayOfWeek"
                :min="OneYearAgo"
                :max="OneYear"
              >
                <div class="row button-row">
                  <button
                    class="btn btn-pill btn-white button-form button-form-primary mr-9"
                    style="min-width: 125px"
                    @click="cancelOriginDatePlannedDialog"
                  >
                    {{ $t("GENERIC.CANCEL") }}
                  </button>
                  <button
                    class="btn btn-pill btn-tertiary button-form"
                    style="min-width: 125px"
                    @click="saveOriginDatePlannedDialog"
                  >
                    {{ $t("GENERIC.OK") }}
                  </button>
                </div>
              </v-date-picker>
            </v-dialog>
            <!--end::OriginDatePlanned dialog-->
            <!--end::OriginDatePlanned-->

            <!--begin::OriginDateDone-->
            <p class="mt-10 mb-3">
              <strong>{{ $t("SHIPMENTS.PLANNING.DONE") }}</strong>
            </p>
            <v-text-field
              class="form-control"
              :class="canEditDriverAndDates ? 'bg-white' : ''"
              :value="formatOneDate('Origin', 'Done')"
              readonly
              @click="openDateDoneDialog('Origin')"
            />

            <!--begin::DateDone dialog-->
            <v-dialog
              v-model="DateDoneDialog.Show"
              :return-value="DateDoneDialog.Value"
              persistent
              width="320px"
            >
              <v-date-picker
                v-model="DateDoneDialog.Value"
                color="tertiary"
                scrollable
                :locale="DateLocale"
                :first-day-of-week="DateFirstDayOfWeek"
                :min="DateDoneDialog.Min"
                :max="Today"
              >
                <div class="row button-row">
                  <button
                    class="btn btn-pill btn-white button-form button-form-primary mr-9"
                    style="min-width: 125px"
                    @click="cancelDateDoneDialog"
                  >
                    {{ $t("GENERIC.CANCEL") }}
                  </button>
                  <button
                    class="btn btn-pill btn-tertiary button-form"
                    style="min-width: 125px"
                    @click="saveDateDoneDialog"
                  >
                    {{ $t("GENERIC.OK") }}
                  </button>
                </div>
              </v-date-picker>
            </v-dialog>
            <!--end::DateDone dialog-->
            <!--end::OriginDateDone-->
          </div>
          <!--end::Origin date-->

          <!--begin::Destination date-->
          <div class="col-lg-6 form-group">
            <h5 class="font-weight-bolder">
              <inline-svg
                src="/media/icons/calendar-alt-1-primary.svg"
                class="mr-3"
              />
              {{ $t("OFFERS.COMMON.DESTINATION_DATE") }}
            </h5>
            <p>
              <strong>
                {{ $t("OFFERS.COMMON.DESTINATION_DATE_RANGE") }}:
              </strong>
              {{ formatDestinationDates("Desired") }}
              <br />
              <strong>
                {{ $t("OFFERS.COMMON.DESTINATION_DATE_TIME") }}:
              </strong>
              {{ formatDestinationTimes() }}
            </p>

            <p class="mb-3">
              <strong>{{ $t("OFFERS.COMMON.PLANNED") }}</strong>
            </p>
            <v-text-field
              class="form-control"
              :class="canEditDriverAndDates ? 'bg-white' : ''"
              :value="formatDestinationDates('Planned')"
              readonly
              @click="openDestinationDatePlannedDialog"
            />

            <!--begin::DestinationDatePlanned dialog-->
            <v-dialog
              v-model="DestinationDatePlannedDialog.Show"
              :return-value="DestinationDatePlannedDialog.Value"
              persistent
              width="320px"
            >
              <v-date-picker
                id="DestinationDatePlannedDialog"
                v-model="DestinationDatePlannedDialog.Value"
                color="tertiary"
                scrollable
                range
                :locale="DateLocale"
                :first-day-of-week="DateFirstDayOfWeek"
                :min="Fields.OriginDatePlannedFrom"
                :max="OneYear"
              >
                <div class="row button-row">
                  <button
                    class="btn btn-pill btn-white button-form button-form-primary mr-9"
                    style="min-width: 125px"
                    @click="cancelDestinationDatePlannedDialog"
                  >
                    {{ $t("GENERIC.CANCEL") }}
                  </button>
                  <button
                    class="btn btn-pill btn-tertiary button-form"
                    style="min-width: 125px"
                    @click="saveDestinationDatePlannedDialog"
                  >
                    {{ $t("GENERIC.OK") }}
                  </button>
                </div>
              </v-date-picker>
            </v-dialog>
            <!--end::DestinationDatePlanned dialog-->

            <!--begin::DestinationDateDone-->
            <p class="mt-10 mb-3">
              <strong>{{ $t("SHIPMENTS.PLANNING.DONE") }}</strong>
            </p>
            <v-text-field
              class="form-control"
              :class="canEditDriverAndDates ? 'bg-white' : ''"
              :value="formatOneDate('Destination', 'Done')"
              readonly
              @click="openDateDoneDialog('Destination')"
            />
            <!--end::DestinationDateDone-->
          </div>
          <!--end::Destination date-->
        </div>
        <!--end::Dates-->

        <!--begin::Notes-->
        <div class="row mt-6">
          <div class="col-lg-12 form-group">
            <label>
              {{ $t("OFFERS.COMMON.SENDER_NOTES") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <p v-if="!canEditSenderSide">
              {{
                InitialData.SenderNotes && InitialData.SenderNotes.length > 0
                  ? InitialData.SenderNotes
                  : $t("GENERIC.N_D")
              }}
            </p>
            <textarea
              v-else
              rows="3"
              class="form-control"
              v-model="Fields.SenderNotes"
            ></textarea>
          </div>

          <div v-if="isTransporter" class="col-lg-12 form-group">
            <label>
              {{ $t("OFFERS.COMMON.TRANSPORTER_NOTES") }}
              <span class="font-weight-normal">
                ({{ $t("GENERIC.OPTIONAL") }})
              </span>
            </label>
            <p v-if="!canEditTransporterSide">
              {{
                InitialData.TransporterNotes &&
                InitialData.TransporterNotes.length > 0
                  ? InitialData.TransporterNotes
                  : $t("GENERIC.N_D")
              }}
            </p>
            <textarea
              v-else
              rows="3"
              class="form-control"
              v-model="Fields.TransporterNotes"
            ></textarea>
          </div>
        </div>
        <!--end::Notes-->
      </v-form>

      <div class="row button-row">
        <button
          class="btn btn-pill btn-white button-form button-form-primary mr-9"
          @click="$router.push(BackRoute)"
        >
          <inline-svg
            src="/media/icons/arrow-return-primary.svg"
            class="mr-3"
          />
          {{ $t("GENERIC.BACK") }}
        </button>

        <button
          v-if="InitialData.CanEdit"
          class="btn btn-pill btn-tertiary button-form"
          :disabled="!Valid"
          @click="onSubmitForm"
        >
          {{ $t("GENERIC.SAVE") }}
        </button>
      </div>
    </div>
  </div>
  <!-- end::Tracking edit -->
</template>

<style>
.priceLine {
  font-size: 1.2em;
  font-weight: 800;
}
.priceTotal {
  font-size: 1.75em !important;
  font-weight: 800;
}
</style>

<script>
import { mapGetters } from "vuex";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";

import moment from "moment";
import i18nService from "@/core/services/i18n.service.js";

import InvoiceCenterService from "@/core/services/api/v2/invoicecenter.service";
import ProvinceService from "@/core/services/api/v2/province.service";
import ShipmentService from "@/core/services/api/v2/shipment.service";
import UserService from "@/core/services/api/v2/user.service";

export default {
  name: "TrackingEdit",
  props: {
    ShipmentId: {
      type: Number,
      required: true,
      default: -1
    },
    ShipmentLegVehicleId: {
      type: Number,
      required: true,
      default: -1
    },
    BackRoute: {
      type: String,
      required: true,
      default: "home"
    }
  },

  data() {
    return {
      Valid: true,
      DetailsOpen: false,
      InitialData: null,
      Fields: {
        DriverUserID: 0,
        OriginDatePlannedFrom: "",
        OriginDatePlannedTo: "",
        DestinationDatePlannedFrom: "",
        DestinationDatePlannedTo: "",
        OriginDateDone: "",
        DestinationDateDone: "",
        SenderNotes: "",
        TransporterNotes: "",
        InvoiceCenterID: 0,
        Observers: []
      },
      ProvinceNames: [],
      DriverOptions: [],
      InvoiceCenterOptions: [],
      ObserverOptions: [],
      ObserverDialog: {
        Show: false,
        Valid: false,
        Nick: "",
        Name: "",
        Email: "",
        NickCompany: ""
      },
      OriginDatePlannedDialog: {
        Show: false,
        Value: []
      },
      DestinationDatePlannedDialog: {
        Show: false,
        Value: []
      },
      DateDoneDialog: {
        WhichSide: "",
        Show: false,
        Value: "",
        Min: ""
      },
      DateFormatEN: "YYYY-MM-DD",
      DateFormatES: "DD/MM/YYYY",
      DateFormatLang: "DD/MM/YYYY",
      DateFirstDayOfWeek: 1,
      DateLocale: "es-es",
      Today: "",
      OneYear: "",
      OneYearAgo: "",
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL")
      }
    };
  },

  computed: {
    ...mapGetters([
      "currentUserRoleID",
      "rolesList",
      "currentUserCompanyIsPremium"
    ]),

    isObserver() {
      return this.currentUserRoleID === this.rolesList.ID_OBSERVER;
    },
    isSender() {
      return (
        this.currentUserRoleID === this.rolesList.ID_SENDER_ADMIN ||
        this.currentUserRoleID === this.rolesList.ID_SENDER_EMPLOYEE
      );
    },
    isTransporter() {
      return this.currentUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN;
    },

    canEditSenderSide() {
      return this.InitialData && this.InitialData.CanEdit && this.isSender;
    },
    canEditTransporterSide() {
      return this.InitialData && this.InitialData.CanEdit && this.isTransporter;
    },
    canEditDriverAndDates() {
      return (
        this.canEditTransporterSide &&
        this.InitialData.DestinationDateDone.length === 0
      );
    }
  },

  created() {
    this.Today = moment().format(this.DateFormatEN);
    this.OneYear = moment()
      .add(1, "years")
      .format(this.DateFormatEN);
    this.OneYearAgo = moment()
      .subtract(1, "years")
      .format(this.DateFormatEN);

    let userLang = i18nService.getActiveLanguage();
    if (userLang === "en") {
      this.DateFirstDayOfWeek = 0;
      this.DateLocale = "en-en";
      this.DateFormatLang = this.DateFormatEN;
    } else {
      this.DateFirstDayOfWeek = 1;
      this.DateLocale = "es-es";
      this.DateFormatLang = this.DateFormatES;
    }
  },

  mounted() {
    // Show page loader
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    this.loadProvinceNames();

    if (this.isSender) {
      this.loadObserverOptions();
      this.loadInvoiceCenterOptions();
    }

    if (this.isTransporter) {
      this.loadDriverOptions();
    }

    this.loadSLVData();
  },

  methods: {
    loadProvinceNames() {
      this.ProvinceNames = [];

      ProvinceService.listAll().then(response => {
        response.forEach(p => {
          this.ProvinceNames[p.ProvinceID] = p.Name;
        });
      });
    },

    loadObserverOptions() {
      this.ObserverOptions = [];

      UserService.listAllObservers(false).then(response => {
        // Parse them into v-select structure
        let list = [];
        response.forEach(o => {
          list.push({
            value: o.UserID,
            text: o.Nick + " (" + o.NickCompany + ")"
          });
        });

        // Sort by text
        list.sort((a, b) => {
          return a.text < b.text;
        });

        this.ObserverOptions = list;
      });
    },

    getCurrentObserversNick() {
      let nicks = [];
      this.ObserverOptions.forEach(o => {
        if (this.Fields.Observers.includes(o.value)) {
          nicks.push(o.text);
        }
      });

      return nicks.join(", ");
    },

    loadInvoiceCenterOptions() {
      this.InvoiceCenterOptions = [];

      if (this.currentUserCompanyIsPremium) {
        InvoiceCenterService.listAll().then(response => {
          let list = [];
          list.push({
            InvoiceCenterID: "",
            Nick: ""
          });

          response.forEach(ic => {
            list.push({
              InvoiceCenterID: ic.InvoiceCenterID,
              Nick: ic.Nick
            });
          });

          this.InvoiceCenterOptions = list;
        });
      }
    },

    getCurrentInvoiceCenterNick() {
      let nick = "";
      for (let i = 0; i < this.InvoiceCenterOptions.length; i++) {
        let maybeCenter = this.InvoiceCenterOptions[i];
        if (
          maybeCenter.InvoiceCenterID ===
          this.InitialData.InvoiceCenter.InvoiceCenterID
        ) {
          nick = maybeCenter.Nick;
          break;
        }
      }

      return nick;
    },

    loadDriverOptions() {
      this.DriverOptions = [];
      this.DriverOptions.push({ UserID: 0, Nick: "" });

      UserService.searchDrivers("", "", "Nick", false, 99, 0).then(
        rawResponse => {
          if (rawResponse.response.Users) {
            rawResponse.response.Users.forEach(u => {
              this.DriverOptions.push({ UserID: u.UserID, Nick: u.Nick });
            });
          }
        }
      );
    },

    getCurrentDriverNick() {
      let nick = "";
      for (let d = 0; d < this.DriverOptions.length; d++) {
        let maybeDriver = this.DriverOptions[d];
        if (maybeDriver.UserID === this.InitialData.DriverUserID) {
          nick = maybeDriver.Nick;
          break;
        }
      }

      return nick;
    },

    loadSLVData() {
      ShipmentService.getAtTracking(this.ShipmentLegVehicleId).then(
        response => {
          if (response === 404) {
            this.$router.push("/manager/shipments");
          } else {
            this.InitialData = response;

            // Pick fields that could be edited
            Object.keys(this.Fields).forEach(f => {
              this.Fields[f] = response[f];
            });

            this.Fields.InvoiceCenterID = response.InvoiceCenter
              ? response.InvoiceCenter.InvoiceCenterID
              : 0;

            // Remove page loader
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }
        }
      );
    },

    formatStatusName() {
      let statusID = this.InitialData ? this.InitialData.ShipmentStatusID : 0;
      if (statusID > 0) {
        return this.$t(
          "SHIPMENT_STATUS_NAMES_BY_ID." + this.InitialData.ShipmentStatusID
        );
      } else {
        return "";
      }
    },

    formatStatusClass() {
      let statusID = this.InitialData ? this.InitialData.ShipmentStatusID : 0;
      if (statusID == 12) {
        // It's cancelled
        return "text-danger border-danger";
      } else if (statusID == 13) {
        // It's successfully finished
        return "text-success border-success";
      } else {
        return "";
      }
    },

    formatTotalPrice() {
      if (!this.InitialData) {
        return "";
      } else {
        let price2 = this.InitialData.Price.toFixed(2);
        let userLang = i18nService.getActiveLanguage();
        if (userLang !== "en") {
          price2 = price2.replace(".", ",");
        }

        return price2 + " " + this.InitialData.Currency;
      }
    },

    formatExtremeAddress(a) {
      return (
        a.PostalCode +
        " - " +
        a.City +
        " (" +
        this.ProvinceNames[a.ProvinceID] +
        ")"
      );
    },

    formatOneDate(whichSide, whichBlock) {
      let value = "";

      if (this.InitialData) {
        let whichDate = "";
        if (whichSide === "Origin" && whichBlock === "Desired") {
          whichDate = this.InitialData.OriginDateDesired;
        } else {
          whichDate = this.Fields[whichSide + "Date" + whichBlock];
        }

        if (
          this.InitialData.ShipmentLegTypeID === 2 &&
          whichSide === "Origin" &&
          parseInt(whichDate.substring(0, 4)) < 2000
        ) {
          // Special value for "Desired Back when There arrives"
          value =
            whichBlock === "Desired"
              ? this.$t("OFFERS.COMMON.ORIGIN_DATE_DESIRED_BACK_THERE")
              : "";
        } else if (whichDate.length > 0) {
          value = moment(whichDate).format(this.DateFormatLang);
        }
      }

      return value;
    },

    formatOriginDatePlanned() {
      let value = "";

      if (this.InitialData) {
        let from = this.Fields.OriginDatePlannedFrom;
        let to = this.Fields.OriginDatePlannedTo;

        if (from === "" && to === "") {
          value = "";
        } else if (from === to) {
          value = moment(from).format(this.DateFormatLang);
        } else {
          value =
            moment(from).format(this.DateFormatLang) +
            " - " +
            moment(to).format(this.DateFormatLang);
        }
      }

      return value;
    },

    formatDestinationDates(whichBlock) {
      let value = "";

      if (this.InitialData) {
        let from = "";
        let to = "";

        if (whichBlock === "Desired") {
          from = this.InitialData.DestinationDateDesiredFrom;
          to = this.InitialData.DestinationDateDesiredTo;
        } else if (whichBlock === "Planned") {
          from = this.Fields.DestinationDatePlannedFrom;
          to = this.Fields.DestinationDatePlannedTo;
        }

        if (from === "" && to === "") {
          value =
            whichBlock === "Desired" ? this.$t("OFFERS.COMMON.FLEXIBLE") : "";
        } else if (from === to) {
          value = moment(from).format(this.DateFormatLang);
        } else {
          value =
            moment(from).format(this.DateFormatLang) +
            " - " +
            moment(to).format(this.DateFormatLang);
        }
      }

      return value;
    },

    formatDestinationTimes() {
      let value = "";

      if (this.InitialData) {
        let from = this.InitialData.DestinationDateDesiredTimeFrom;
        let to = this.InitialData.DestinationDateDesiredTimeTo;
        value = from + " - " + to;
      }

      return value;
    },

    openObserverDialog() {
      if (this.canEditSenderSide) {
        this.ObserverDialog.Nick = "";
        this.ObserverDialog.NickCompany = "";
        this.ObserverDialog.Name = "";
        this.ObserverDialog.Email = "";
        this.ObserverDialog.Show = true;
      }
    },

    saveObserverDialog(e) {
      e.preventDefault();

      if (this.canEditSenderSide && this.$refs.formObserverDialog.validate()) {
        // Disable submit button
        this.ObserverDialog.Valid = false;

        // Send request to create new Observer: we get back his ID
        UserService.createObserverProfile(this.ObserverDialog).then(
          response => {
            if (response.UserID) {
              this.$bvToast.toast(" ", {
                title: this.$i18n.t("GENERIC.SAVE_OK"),
                variant: "success",
                solid: true
              });

              this.loadObserverOptions();
            } else {
              let whyError = this.$i18n.t("GENERIC.TRY_LATER");
              if (response === 409) {
                whyError = this.$i18n.t("OBSERVERS.ERROR_409");
              } else if (response === 418) {
                whyError = this.$i18n.t("OBSERVERS.ERROR_418");
              } else if (response === 400) {
                whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
              }

              this.$bvToast.toast(whyError, {
                title: this.$i18n.t("GENERIC.SAVE_ERROR"),
                variant: "danger",
                solid: true
              });
            }

            // Reset submit button and hide dialog
            this.ObserverDialog.Valid = true;
            this.ObserverDialog.Show = false;
          }
        );
      }
    },

    openOriginDatePlannedDialog() {
      if (this.canEditDriverAndDates) {
        let oldValueFrom = this.Fields.OriginDatePlannedFrom;
        if (
          this.InitialData.ShipmentLegTypeID === 2 &&
          parseInt(oldValueFrom.substring(0, 4)) < 2000
        ) {
          // Special value for "Back when There arrives" - but now we need a specific date
          oldValueFrom = moment().format(this.DateFormatEN);
          this.OriginDatePlannedDialog.Value = [oldValueFrom, oldValueFrom];
        }

        this.OriginDatePlannedDialog.Show = true;
      }
    },

    saveOriginDatePlannedDialog() {
      if (this.canEditDriverAndDates) {
        if (this.OriginDatePlannedDialog.Value.length === 2) {
          let newValues = this.OriginDatePlannedDialog.Value.sort();
          this.Fields.OriginDatePlannedFrom = newValues[0];
          this.Fields.OriginDatePlannedTo = newValues[1];
        }

        this.OriginDatePlannedDialog.Show = false;
      }
    },

    cancelOriginDatePlannedDialog() {
      if (this.canEditDriverAndDates) {
        // Reset picker to old date
        this.OriginDatePlannedDialog.Value = [
          this.Fields.OriginDatePlannedFrom,
          this.Fields.OriginDatePlannedTo
        ];

        this.OriginDatePlannedDialog.Show = false;
      }
    },

    openDateDoneDialog(whichSide) {
      if (this.canEditDriverAndDates) {
        this.DateDoneDialog.WhichSide = whichSide;

        let oldValue =
          whichSide === "Origin"
            ? this.Fields.OriginDateDone
            : this.Fields.DestinationDateDone;

        // From 2018 to 2021 no Transporter bothers to fill the DoneDates hour;
        // that's why we don't use a date-time-picker, just pad it
        this.DateDoneDialog.Value =
          oldValue.length > 0 ? moment(oldValue).format(this.DateFormatEN) : "";

        this.DateDoneDialog.Min = this.OneYearAgo;
        if (
          whichSide === "Destination" &&
          this.Fields.OriginDateDone.length > 0
        ) {
          this.DateDoneDialog.Min = moment(this.Fields.OriginDateDone).format(
            this.DateFormatEN
          );
        }

        this.DateDoneDialog.Show = true;
      }
    },

    saveDateDoneDialog() {
      if (this.canEditDriverAndDates) {
        let newDate = this.DateDoneDialog.Value;

        // From 2018 to 2021 no Transporter bothers to fill the DoneDates hour;
        // that's why we don't use a date-time-picker, just pad it
        newDate += " 00:00:00";
        this.Fields[this.DateDoneDialog.WhichSide + "DateDone"] = newDate;

        this.DateDoneDialog.Show = false;
      }
    },

    cancelDateDoneDialog() {
      if (this.canEditDriverAndDates) {
        // Reset picker to old date
        let oldDate = this.Fields[this.DateDoneDialog.WhichSide + "DateDone"];
        this.DateDoneDialog.Value = oldDate;

        this.DateDoneDialog.Show = false;
      }
    },

    openDestinationDatePlannedDialog() {
      if (this.canEditDriverAndDates) {
        this.DestinationDatePlannedDialog.Show = true;
      }
    },

    saveDestinationDatePlannedDialog() {
      if (this.canEditDriverAndDates) {
        if (this.DestinationDatePlannedDialog.Value.length === 2) {
          let newValues = this.DestinationDatePlannedDialog.Value.sort();
          this.Fields.DestinationDatePlannedFrom = newValues[0];
          this.Fields.DestinationDatePlannedTo = newValues[1];
        }

        this.DestinationDatePlannedDialog.Show = false;
      }
    },

    cancelDestinationDatePlannedDialog() {
      if (this.canEditDriverAndDates) {
        // Reset picker to old date
        this.DestinationDatePlannedDialog.Value = [
          this.Fields.DestinationDateDesiredFrom,
          this.Fields.DestinationDateDesiredTo
        ];

        this.DestinationDatePlannedDialog.Show = false;
      }
    },

    onSubmitForm(e) {
      e.preventDefault();

      if (this.$refs.formBasic.validate()) {
        // Disable submit button
        this.Valid = false;

        // From 2018 to 2021 no Transporter bothers to fill the DoneDates hour;
        // that's why we don't use a date-time-picker, just pad it
        if (this.Fields.OriginDateDone.length > 0) {
          this.Fields.OriginDateDone =
            moment(this.Fields.OriginDateDone).format(this.DateFormatEN) +
            " 00:00:00";
        }
        if (this.Fields.DestinationDateDone.length > 0) {
          this.Fields.DestinationDateDone =
            moment(this.Fields.DestinationDateDone).format(this.DateFormatEN) +
            " 00:00:00";
        }

        // Send request:
        ShipmentService.updateAtTracking(
          this.ShipmentLegVehicleId,
          this.Fields
        ).then(statusCode => {
          if (statusCode >= 400) {
            let whyError = this.$i18n.t("GENERIC.TRY_LATER");
            if (statusCode === 400) {
              whyError = this.$i18n.t("VALIDATION.MISSING_REQUIRED_FIELDS");
            } else if (statusCode === 409) {
              whyError = this.$i18n.t("VALIDATION.INVALID_DATES");
            }

            this.$bvToast.toast(whyError, {
              title: this.$i18n.t("GENERIC.SAVE_ERROR"),
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast(" ", {
              title: this.$i18n.t("GENERIC.SAVE_OK"),
              variant: "success",
              solid: true
            });

            // Reload data, because maybe ShipmentStatus changed, or now can't edit anymore
            this.loadSLVData();
          }

          // Reset submit button
          this.Valid = true;
        });
      }
    }
  }
};
</script>
