import ApiService from "@/core/services/api/v2/api.service";

const OFFER_FILTER_KEY = "OfferStandardFilter";
const TRACKING_FILTER_KEY = "TrackingFilter";
const INCIDENT_FILTER_KEY = "IncidentFilter";
const SUPERADMIN_FILTER_KEY = "SuperadminFilter";

const ShipmentService = {
  // 1. At Offer Negotiation phase

  // 1.1. Search & view
  searchAtOffer(
    searchText,
    filterByOriginProvinceID,
    filterByDestinationProvinceID,
    filterByShipmentStatusID,
    filterByCompanyName,
    filterByLegCount,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByOriginProvinceID = parseInt(filterByOriginProvinceID);
      if (!isNaN(filterByOriginProvinceID) && filterByOriginProvinceID > 0) {
        queryParams.OriginProvinceID = filterByOriginProvinceID;
      }

      filterByDestinationProvinceID = parseInt(filterByDestinationProvinceID);
      if (
        !isNaN(filterByDestinationProvinceID) &&
        filterByDestinationProvinceID > 0
      ) {
        queryParams.DestinationProvinceID = filterByDestinationProvinceID;
      }

      filterByShipmentStatusID = parseInt(filterByShipmentStatusID);
      if (!isNaN(filterByShipmentStatusID) && filterByShipmentStatusID > 0) {
        queryParams.ShipmentStatusID = filterByShipmentStatusID;
      }

      filterByCompanyName = filterByCompanyName.trim();
      if (filterByCompanyName.length > 0) {
        queryParams.CompanyName = filterByCompanyName;
      }

      filterByLegCount = parseInt(filterByLegCount);
      if (!isNaN(filterByLegCount) && filterByLegCount > 0) {
        queryParams.LegCount = filterByLegCount;
      }

      window.sessionStorage.setItem(
        OFFER_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("shipment/offer", { params: queryParams })
        .then(response => {
          // Return full response, not just "Shipments" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFiltersAtOffer() {
    let cachedFiltersAtOffer = window.sessionStorage.getItem(OFFER_FILTER_KEY);
    if (cachedFiltersAtOffer) {
      return JSON.parse(cachedFiltersAtOffer);
    } else {
      return {};
    }
  },

  downloadExcelAtOffer(
    searchText,
    filterByOriginProvinceID,
    filterByDestinationProvinceID,
    filterByShipmentStatusID,
    filterByCompanyName,
    filterByLegCount,
    orderBy,
    orderDesc
  ) {
    return new Promise(resolve => {
      // Parse filter params, alike in search
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByOriginProvinceID = parseInt(filterByOriginProvinceID);
      if (!isNaN(filterByOriginProvinceID) && filterByOriginProvinceID > 0) {
        queryParams.OriginProvinceID = filterByOriginProvinceID;
      }

      filterByDestinationProvinceID = parseInt(filterByDestinationProvinceID);
      if (
        !isNaN(filterByDestinationProvinceID) &&
        filterByDestinationProvinceID > 0
      ) {
        queryParams.DestinationProvinceID = filterByDestinationProvinceID;
      }

      filterByShipmentStatusID = parseInt(filterByShipmentStatusID);
      if (!isNaN(filterByShipmentStatusID) && filterByShipmentStatusID > 0) {
        queryParams.ShipmentStatusID = filterByShipmentStatusID;
      }

      filterByCompanyName = filterByCompanyName.trim();
      if (filterByCompanyName.length > 0) {
        queryParams.CompanyName = filterByCompanyName;
      }

      filterByLegCount = parseInt(filterByLegCount);
      if (!isNaN(filterByLegCount) && filterByLegCount > 0) {
        queryParams.LegCount = filterByLegCount;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      ApiService.queryBlob("shipment/offer/export", { params: queryParams })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getAtOffer(shipmentID) {
    return new Promise(resolve => {
      ApiService.get("shipment/offer/" + shipmentID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getAlertCountAtOffer(shipmentID) {
    return new Promise(resolve => {
      ApiService.get("shipment/offer/" + shipmentID + "/alertCount")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // 1.2. Update

  updateAtOffer(shipmentID, newData) {
    return new Promise(resolve => {
      ApiService.put("shipment/offer/" + shipmentID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  cancelAtOffer(shipmentID) {
    return new Promise(resolve => {
      ApiService.delete("shipment/offer/" + shipmentID + "/cancel")
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  acceptAtOffer(shipmentID) {
    return new Promise(resolve => {
      ApiService.post("shipment/offer/" + shipmentID)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  rejectAtOffer(shipmentID) {
    return new Promise(resolve => {
      ApiService.delete("shipment/offer/" + shipmentID)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // 1.3. Chat

  getChatAtOffer(shipmentID) {
    return new Promise(resolve => {
      let getUrl = "shipment/offer/" + shipmentID + "/chat?Limit=99";

      ApiService.get(getUrl)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getChatNewAtOffer(shipmentID, lastReadShipmentChatID) {
    return new Promise(resolve => {
      let getUrl =
        "shipment/offer/" + shipmentID + "/chat/new/" + lastReadShipmentChatID;

      ApiService.get(getUrl)
        .then(response => {
          resolve(response.data.response.Messages);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  sendChatAtOffer(shipmentID, msgContent) {
    return new Promise(resolve => {
      let postUrl = "shipment/offer/" + shipmentID + "/chat";

      ApiService.post(postUrl, { Content: msgContent })
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // 2. After Offer phase, a.k.a. Tracking phase

  // 2.1. Search & view (per children ShipmentLegVehicle!)
  searchAtTracking(
    searchText,
    filterByOriginProvinceID,
    filterByDestinationProvinceID,
    filterByShipmentStatusID,
    filterByCompanyName,
    filterByLegTypeID,
    filterByDriverUserID,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByOriginProvinceID = parseInt(filterByOriginProvinceID);
      if (!isNaN(filterByOriginProvinceID) && filterByOriginProvinceID > 0) {
        queryParams.OriginProvinceID = filterByOriginProvinceID;
      }

      filterByDestinationProvinceID = parseInt(filterByDestinationProvinceID);
      if (
        !isNaN(filterByDestinationProvinceID) &&
        filterByDestinationProvinceID > 0
      ) {
        queryParams.DestinationProvinceID = filterByDestinationProvinceID;
      }

      filterByShipmentStatusID = parseInt(filterByShipmentStatusID);
      if (!isNaN(filterByShipmentStatusID) && filterByShipmentStatusID !== 0) {
        queryParams.ShipmentStatusID = filterByShipmentStatusID;
      }

      filterByCompanyName = filterByCompanyName.trim();
      if (filterByCompanyName.length > 0) {
        queryParams.CompanyName = filterByCompanyName;
      }

      filterByLegTypeID = parseInt(filterByLegTypeID);
      if (!isNaN(filterByLegTypeID) && filterByLegTypeID > 0) {
        queryParams.ShipmentLegTypeID = filterByLegTypeID;
      }

      filterByDriverUserID = parseInt(filterByDriverUserID);
      if (!isNaN(filterByDriverUserID) && filterByDriverUserID > 0) {
        queryParams.DriverUserID = filterByDriverUserID;
      }

      window.sessionStorage.setItem(
        TRACKING_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("shipment/tracking", { params: queryParams })
        .then(response => {
          // Return full response, not just "ShipmentLegVehicles" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  downloadExcelAtTracking(
    searchText,
    filterByOriginProvinceID,
    filterByDestinationProvinceID,
    filterByShipmentStatusID,
    filterByCompanyName,
    filterByLegTypeID,
    filterByDriverUserID,
    orderBy,
    orderDesc
  ) {
    return new Promise(resolve => {
      // Parse filter params, alike in search
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByOriginProvinceID = parseInt(filterByOriginProvinceID);
      if (!isNaN(filterByOriginProvinceID) && filterByOriginProvinceID > 0) {
        queryParams.OriginProvinceID = filterByOriginProvinceID;
      }

      filterByDestinationProvinceID = parseInt(filterByDestinationProvinceID);
      if (
        !isNaN(filterByDestinationProvinceID) &&
        filterByDestinationProvinceID > 0
      ) {
        queryParams.DestinationProvinceID = filterByDestinationProvinceID;
      }

      filterByShipmentStatusID = parseInt(filterByShipmentStatusID);
      if (!isNaN(filterByShipmentStatusID) && filterByShipmentStatusID !== 0) {
        queryParams.ShipmentStatusID = filterByShipmentStatusID;
      }

      filterByCompanyName = filterByCompanyName.trim();
      if (filterByCompanyName.length > 0) {
        queryParams.CompanyName = filterByCompanyName;
      }

      filterByLegTypeID = parseInt(filterByLegTypeID);
      if (!isNaN(filterByLegTypeID) && filterByLegTypeID > 0) {
        queryParams.ShipmentLegTypeID = filterByLegTypeID;
      }

      filterByDriverUserID = parseInt(filterByDriverUserID);
      if (!isNaN(filterByDriverUserID) && filterByDriverUserID > 0) {
        queryParams.DriverUserID = filterByDriverUserID;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      ApiService.queryBlob("shipment/tracking/export", { params: queryParams })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getCachedFiltersAtTracking() {
    let cachedFiltersAtTracking = window.sessionStorage.getItem(
      TRACKING_FILTER_KEY
    );

    if (cachedFiltersAtTracking) {
      return JSON.parse(cachedFiltersAtTracking);
    } else {
      return {};
    }
  },

  getAtTracking(shipmentLegVehicleID) {
    return new Promise(resolve => {
      ApiService.get("shipment/tracking/" + shipmentLegVehicleID)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getAlertCountAtTracking(shipmentLegVehicleID) {
    return new Promise(resolve => {
      ApiService.get(
        "shipment/tracking/" + shipmentLegVehicleID + "/alertCount"
      )
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // 2.2. Update (per children ShipmentLegVehicle!)

  updateAtTracking(shipmentLegVehicleID, newData) {
    return new Promise(resolve => {
      ApiService.put("shipment/tracking/" + shipmentLegVehicleID, newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateMultipleAtTracking(newData) {
    return new Promise(resolve => {
      ApiService.post("shipment/tracking", newData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // 2.3. Incidents (per children ShipmentLegVehicle!)

  getIncidentListAtTracking(shipmentLegVehicleID) {
    return new Promise(resolve => {
      let getUrl = "shipment/tracking/" + shipmentLegVehicleID + "/incident";

      ApiService.get(getUrl)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  createIncidentAtTracking(shipmentLegVehicleID, newData) {
    return new Promise(resolve => {
      let postUrl = "shipment/tracking/" + shipmentLegVehicleID + "/incident";

      ApiService.post(postUrl, newData)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getIncidentAtTracking(shipmentLegVehicleID, shipmentLegVehicleIncidentID) {
    return new Promise(resolve => {
      let getUrl =
        "shipment/tracking/" +
        shipmentLegVehicleID +
        "/incident/" +
        shipmentLegVehicleIncidentID;

      ApiService.get(getUrl)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  updateIncidentAtTracking(
    shipmentLegVehicleID,
    shipmentLegVehicleIncidentID,
    newData
  ) {
    return new Promise(resolve => {
      let putUrl =
        "shipment/tracking/" +
        shipmentLegVehicleID +
        "/incident/" +
        shipmentLegVehicleIncidentID;

      ApiService.put(putUrl, newData)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteIncidentAtTracking(shipmentLegVehicleID, shipmentLegVehicleIncidentID) {
    return new Promise(resolve => {
      let deleteUrl =
        "shipment/tracking/" +
        shipmentLegVehicleID +
        "/incident/" +
        shipmentLegVehicleIncidentID;

      ApiService.delete(deleteUrl)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // 2.4. Attached docs (over parent's Shipment!)

  getDocumentsAtTracking(shipmentID) {
    return new Promise(resolve => {
      ApiService.get("shipment/tracking/" + shipmentID + "/doc")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  uploadDocumentAtTracking(shipmentID, newDocument) {
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append("Document", newDocument);

      ApiService.post("shipment/tracking/" + shipmentID + "/doc", formData)
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  deleteDocumentAtTracking(shipmentID, shipmentDocumentID) {
    return new Promise(resolve => {
      ApiService.delete(
        "shipment/tracking/" + shipmentID + "/doc/" + shipmentDocumentID
      )
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // 2.5. Chat (over parent's Shipment!)

  getChatAtTracking(shipmentID) {
    return new Promise(resolve => {
      let getUrl = "shipment/tracking/" + shipmentID + "/chat?Limit=99";

      ApiService.get(getUrl)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  getChatNewAtTracking(shipmentID, lastReadShipmentChatID) {
    return new Promise(resolve => {
      let getUrl =
        "shipment/tracking/" +
        shipmentID +
        "/chat/new/" +
        lastReadShipmentChatID;

      ApiService.get(getUrl)
        .then(response => {
          resolve(response.data.response.Messages);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  sendChatAtTracking(shipmentID, msgContent) {
    return new Promise(resolve => {
      let postUrl = "shipment/tracking/" + shipmentID + "/chat";

      ApiService.post(postUrl, { Content: msgContent })
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  // 3. All chats, either phase

  getAllChatsAlertCount() {
    return new Promise(resolve => {
      ApiService.get("shipment/chat/alertCount")
        .then(response => {
          resolve(response.data.response);
        })
        .catch(() => {
          resolve(0);
        });
    });
  },

  searchAllChats(atOffer, searchText, orderBy, orderDesc, limit, offset) {
    return new Promise(resolve => {
      // Parse params
      const queryParams = { AtOffer: atOffer };

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("shipment/chat/search", { params: queryParams })
        .then(response => {
          // Return full response, not just "Chats" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  // 4. All unfinished incidents

  getAllUnfinishedIncidentsCount() {
    return new Promise(resolve => {
      ApiService.get("shipment/incident/unfinishedCount")
        .then(response => {
          resolve(response.data.response.UnfinishedIncidentsCount);
        })
        .catch(() => {
          resolve(0);
        });
    });
  },

  searchAllUnfinishedIncidents(
    searchText,
    filterByOriginProvinceID,
    filterByDestinationProvinceID,
    filterByCompanyName,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByOriginProvinceID = parseInt(filterByOriginProvinceID);
      if (!isNaN(filterByOriginProvinceID) && filterByOriginProvinceID > 0) {
        queryParams.OriginProvinceID = filterByOriginProvinceID;
      }

      filterByDestinationProvinceID = parseInt(filterByDestinationProvinceID);
      if (
        !isNaN(filterByDestinationProvinceID) &&
        filterByDestinationProvinceID > 0
      ) {
        queryParams.DestinationProvinceID = filterByDestinationProvinceID;
      }

      filterByCompanyName = filterByCompanyName.trim();
      if (filterByCompanyName.length > 0) {
        queryParams.CompanyName = filterByCompanyName;
      }

      window.sessionStorage.setItem(
        INCIDENT_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("shipment/incident/unfinished", { params: queryParams })
        .then(response => {
          // Return full response, not just "ShipmentLegVehicleIncidents" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFiltersAtIncidents() {
    let cachedFiltersAtIncidents = window.sessionStorage.getItem(
      INCIDENT_FILTER_KEY
    );

    if (cachedFiltersAtIncidents) {
      return JSON.parse(cachedFiltersAtIncidents);
    } else {
      return {};
    }
  },

  // 5. Full search for Superadmin

  searchBySuperadmin(
    searchText,
    filterByShipmentStatusID,
    filterByCreatedAtFrom,
    filterByCreatedAtTo,
    filterByFinishedAtFrom,
    filterByFinishedAtTo,
    orderBy,
    orderDesc,
    limit,
    offset
  ) {
    return new Promise(resolve => {
      // Parse params - storing a cache of the filters applied
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByShipmentStatusID = parseInt(filterByShipmentStatusID);
      if (!isNaN(filterByShipmentStatusID) && filterByShipmentStatusID > 0) {
        queryParams.ShipmentStatusID = filterByShipmentStatusID;
      }

      filterByCreatedAtFrom = filterByCreatedAtFrom.trim();
      if (filterByCreatedAtFrom.length > 0) {
        queryParams.CreatedAtFrom = filterByCreatedAtFrom;
      }

      filterByCreatedAtTo = filterByCreatedAtTo.trim();
      if (filterByCreatedAtTo.length > 0) {
        queryParams.CreatedAtTo = filterByCreatedAtTo;
      }

      filterByFinishedAtFrom = filterByFinishedAtFrom.trim();
      if (filterByFinishedAtFrom.length > 0) {
        queryParams.FinishedAtFrom = filterByFinishedAtFrom;
      }

      filterByFinishedAtTo = filterByFinishedAtTo.trim();
      if (filterByFinishedAtTo.length > 0) {
        queryParams.FinishedAtTo = filterByFinishedAtTo;
      }

      window.sessionStorage.setItem(
        SUPERADMIN_FILTER_KEY,
        JSON.stringify(queryParams)
      );

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      limit = parseInt(limit);
      if (!isNaN(limit) && limit > 0) {
        queryParams.Limit = limit;
      }

      offset = parseInt(offset);
      if (!isNaN(offset)) {
        queryParams.Offset = offset;
      }

      // Do search
      ApiService.query("shipment", { params: queryParams })
        .then(response => {
          // Return full response, not just "Shipments" array, for pagination etc
          resolve(response.data);
        })
        .catch(() => {
          resolve(404);
        });
    });
  },

  getCachedFiltersBySuperadmin() {
    let cachedFiltersBySuperadmin = window.sessionStorage.getItem(
      SUPERADMIN_FILTER_KEY
    );

    if (cachedFiltersBySuperadmin) {
      return JSON.parse(cachedFiltersBySuperadmin);
    } else {
      return {};
    }
  },

  downloadExcelBySuperadmin(
    searchText,
    filterByShipmentStatusID,
    filterByCreatedAtFrom,
    filterByCreatedAtTo,
    filterByFinishedAtFrom,
    filterByFinishedAtTo,
    orderBy,
    orderDesc
  ) {
    return new Promise(resolve => {
      // Parse filter params, alike in search
      const queryParams = {};

      searchText = searchText.trim();
      if (searchText.length > 0) {
        queryParams.Search = searchText;
      }

      filterByShipmentStatusID = parseInt(filterByShipmentStatusID);
      if (!isNaN(filterByShipmentStatusID) && filterByShipmentStatusID > 0) {
        queryParams.ShipmentStatusID = filterByShipmentStatusID;
      }

      filterByCreatedAtFrom = filterByCreatedAtFrom.trim();
      if (filterByCreatedAtFrom.length > 0) {
        queryParams.CreatedAtFrom = filterByCreatedAtFrom;
      }

      filterByCreatedAtTo = filterByCreatedAtTo.trim();
      if (filterByCreatedAtTo.length > 0) {
        queryParams.CreatedAtTo = filterByCreatedAtTo;
      }

      filterByFinishedAtFrom = filterByFinishedAtFrom.trim();
      if (filterByFinishedAtFrom.length > 0) {
        queryParams.FinishedAtFrom = filterByFinishedAtFrom;
      }

      filterByFinishedAtTo = filterByFinishedAtTo.trim();
      if (filterByFinishedAtTo.length > 0) {
        queryParams.FinishedAtTo = filterByFinishedAtTo;
      }

      orderBy = orderBy ? orderBy.trim() : "";
      if (orderBy.length > 0) {
        queryParams.OrderBy = orderBy;
      }

      queryParams.OrderDesc = orderDesc ? 1 : 0;

      ApiService.queryBlob("shipment/export", { params: queryParams })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default ShipmentService;
