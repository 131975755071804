<template>
  <!-- begin::Tracking incidents index -->
  <div class="white-box shadow-box p-6">
    <div
      class="manager-subtitle d-flex align-items-center justify-content-between"
    >
      <div>{{ $t("SHIPMENTS.INCIDENTS.TITLE_2") }}</div>

      <div v-if="allowModify">
        <button
          class="btn button-filter btn-pill btn-tertiary"
          @click="
            $router.push(
              '/manager/shipments/edit/' +
                ShipmentLegVehicleId +
                '/incidents/new'
            )
          "
        >
          {{ $t("SHIPMENTS.INCIDENTS.TITLE_ADD") }}
        </button>
      </div>
    </div>

    <div v-if="Loading" class="text-primary pl-3">
      {{ $t("GENERIC.SEARCHING") }}...
    </div>

    <div v-if="!Loading && IncidentList.length < 1" class="text-danger pl-3">
      {{ $t("GENERIC.NO_RESULTS") }}
    </div>

    <div
      v-for="(item, index) in IncidentList"
      :key="index"
      class="row py-3 white-table border-box shadow-box mb-6"
      :class="item.FinishedAt.length === 0 ? 'bg-danger-o-30' : ''"
    >
      <div class="col-lg-6">
        <strong>{{ item.IncidentTypeName }}</strong>
        <div v-if="item.Notes && item.Notes.length > 0">
          {{ item.Notes }}
        </div>
      </div>

      <div class="col-lg-2 pt-3">
        {{ formatPrice(item.PriceIncrease, item.Currency) }}
      </div>

      <div :class="allowModify ? 'col-lg-2' : 'col-lg-4'" class="pt-3">
        <span
          class="white-box border-2 p-3 font-size-sm text-uppercase"
          :class="
            item.FinishedAt.length > 0
              ? 'text-black border-black'
              : 'text-danger border-danger'
          "
          style="white-space: nowrap"
        >
          {{
            item.FinishedAt.length > 0
              ? $t("SHIPMENTS.INCIDENTS.FINISHED")
              : $t("SHIPMENTS.INCIDENTS.UNFINISHED")
          }}
        </span>
      </div>

      <div
        v-if="allowModify"
        class="col-lg-2 pb-2 d-flex align-items-center justify-content-end"
      >
        <span
          class="action-icon"
          @click="
            $router.push(
              '/manager/shipments/edit/' +
                ShipmentLegVehicleId +
                '/incidents/edit/' +
                item.ShipmentLegVehicleIncidentID
            )
          "
        >
          <inline-svg src="/media/icons/edit-alt-2-primary.svg" />
        </span>
        <span class="action-icon ml-6" @click="deleteIncident(index)">
          <inline-svg src="/media/icons/delete-alt-2-primary.svg" />
        </span>
      </div>
    </div>

    <div class="row mt-12 mb-6">
      <button
        class="btn btn-pill btn-white button-form button-form-primary ml-9"
        @click="$router.push(BackRoute)"
      >
        <inline-svg src="/media/icons/arrow-return-primary.svg" class="mr-3" />
        {{ $t("GENERIC.BACK") }}
      </button>
    </div>
  </div>
  <!-- end::Tracking incidents index -->
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import i18nService from "@/core/services/i18n.service.js";

import ShipmentService from "@/core/services/api/v2/shipment.service";

export default {
  name: "TrackingIncidentsIndex",
  props: {
    ShipmentId: {
      type: Number,
      required: false,
      default: -1
    },
    ShipmentLegVehicleId: {
      type: Number,
      required: true,
      default: -1
    },
    BackRoute: {
      type: String,
      required: true,
      default: "home"
    }
  },

  data() {
    return {
      Loading: false,
      IncidentList: []
    };
  },

  computed: {
    ...mapGetters(["currentUserRoleID", "rolesList"]),

    allowModify() {
      return this.currentUserRoleID === this.rolesList.ID_TRANSPORTER_ADMIN;
    }
  },

  mounted() {
    this.loadIncidentList();
  },

  methods: {
    loadIncidentList() {
      this.Loading = true;
      ShipmentService.getIncidentListAtTracking(this.ShipmentLegVehicleId).then(
        response => {
          if (response === 404) {
            this.$router.push("/manager/shipments");
          } else {
            this.IncidentList = response.ShipmentLegVehicleIncidents;
          }

          this.Loading = false;
        }
      );
    },

    formatPrice(price, currency) {
      if (!price) {
        return "";
      } else {
        let price2 = price.toFixed(2);
        let userLang = i18nService.getActiveLanguage();
        if (userLang !== "en") {
          price2 = price2.replace(".", ",");
        }

        return price2 + " " + currency;
      }
    },

    deleteIncident(index) {
      let whichIncident = this.IncidentList[index];
      if (whichIncident) {
        Swal.fire({
          title:
            this.$i18n.t("GENERIC.DELETE_ALERT") +
            "\n" +
            whichIncident.IncidentTypeName,
          text: this.$i18n.t("GENERIC.DELETE_ALERT2"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$i18n.t("GENERIC.DELETE_YES"),
          cancelButtonText: this.$i18n.t("GENERIC.DELETE_NO"),
          customClass: {
            confirmButton: "btn btn-pill btn-danger button-form",
            cancelButton:
              "btn btn-pill btn-white button-form button-form-primary"
          },
          buttonsStyling: false
        }).then(result => {
          if (result.isConfirmed) {
            ShipmentService.deleteIncidentAtTracking(
              this.ShipmentLegVehicleId,
              whichIncident.ShipmentLegVehicleIncidentID
            ).then(statusCode => {
              if (statusCode >= 400) {
                this.$bvToast.toast(this.$i18n.t("GENERIC.TRY_LATER"), {
                  title: this.$i18n.t("GENERIC.DELETE_ERROR"),
                  variant: "danger",
                  solid: true
                });
              } else {
                Swal.fire({
                  text: this.$i18n.t("GENERIC.DELETE_OK"),
                  icon: "success",
                  confirmButtonText: this.$i18n.t("GENERIC.CLOSE"),
                  customClass: {
                    confirmButton: "btn btn-tertiary btn-lg font-weight-bolder"
                  },
                  buttonsStyling: false,
                  focusConfirm: false
                });

                this.loadIncidentList();
              }
            });
          }
        });
      }
    }
  }
};
</script>
