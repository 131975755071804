var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white-box shadow-box p-6"},[_c('div',{staticClass:"manager-subtitle d-flex align-items-center justify-content-between"},[_c('div',[_vm._v(_vm._s(_vm.$t("SHIPMENTS.INCIDENTS.TITLE_2")))]),(_vm.allowModify)?_c('div',[_c('button',{staticClass:"btn button-filter btn-pill btn-tertiary",on:{"click":function($event){return _vm.$router.push(
            '/manager/shipments/edit/' +
              _vm.ShipmentLegVehicleId +
              '/incidents/new'
          )}}},[_vm._v(" "+_vm._s(_vm.$t("SHIPMENTS.INCIDENTS.TITLE_ADD"))+" ")])]):_vm._e()]),(_vm.Loading)?_c('div',{staticClass:"text-primary pl-3"},[_vm._v(" "+_vm._s(_vm.$t("GENERIC.SEARCHING"))+"... ")]):_vm._e(),(!_vm.Loading && _vm.IncidentList.length < 1)?_c('div',{staticClass:"text-danger pl-3"},[_vm._v(" "+_vm._s(_vm.$t("GENERIC.NO_RESULTS"))+" ")]):_vm._e(),_vm._l((_vm.IncidentList),function(item,index){return _c('div',{key:index,staticClass:"row py-3 white-table border-box shadow-box mb-6",class:item.FinishedAt.length === 0 ? 'bg-danger-o-30' : ''},[_c('div',{staticClass:"col-lg-6"},[_c('strong',[_vm._v(_vm._s(item.IncidentTypeName))]),(item.Notes && item.Notes.length > 0)?_c('div',[_vm._v(" "+_vm._s(item.Notes)+" ")]):_vm._e()]),_c('div',{staticClass:"col-lg-2 pt-3"},[_vm._v(" "+_vm._s(_vm.formatPrice(item.PriceIncrease, item.Currency))+" ")]),_c('div',{staticClass:"pt-3",class:_vm.allowModify ? 'col-lg-2' : 'col-lg-4'},[_c('span',{staticClass:"white-box border-2 p-3 font-size-sm text-uppercase",class:item.FinishedAt.length > 0
            ? 'text-black border-black'
            : 'text-danger border-danger',staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.FinishedAt.length > 0 ? _vm.$t("SHIPMENTS.INCIDENTS.FINISHED") : _vm.$t("SHIPMENTS.INCIDENTS.UNFINISHED"))+" ")])]),(_vm.allowModify)?_c('div',{staticClass:"col-lg-2 pb-2 d-flex align-items-center justify-content-end"},[_c('span',{staticClass:"action-icon",on:{"click":function($event){return _vm.$router.push(
            '/manager/shipments/edit/' +
              _vm.ShipmentLegVehicleId +
              '/incidents/edit/' +
              item.ShipmentLegVehicleIncidentID
          )}}},[_c('inline-svg',{attrs:{"src":"/media/icons/edit-alt-2-primary.svg"}})],1),_c('span',{staticClass:"action-icon ml-6",on:{"click":function($event){return _vm.deleteIncident(index)}}},[_c('inline-svg',{attrs:{"src":"/media/icons/delete-alt-2-primary.svg"}})],1)]):_vm._e()])}),_c('div',{staticClass:"row mt-12 mb-6"},[_c('button',{staticClass:"btn btn-pill btn-white button-form button-form-primary ml-9",on:{"click":function($event){return _vm.$router.push(_vm.BackRoute)}}},[_c('inline-svg',{staticClass:"mr-3",attrs:{"src":"/media/icons/arrow-return-primary.svg"}}),_vm._v(" "+_vm._s(_vm.$t("GENERIC.BACK"))+" ")],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }